<template>
  <div>
    <header class="mb-auto" style="background-color: rgb(0, 153, 255)">
      <nav>
        <span class="navbar-brand mb-0 h1" style="color: white"
          >ストレスチェック受診者ログインページ</span
        >
      </nav>
    </header>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>

      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div v-show="!$store.state.common.sIsLoading">
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">企業ID</label>
                  </div>
                  <input class="uk-input" type="text" v-model="alias_id" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">社員ID</label>
                  </div>
                  <input class="uk-input" type="text" v-model="vLoginId" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">{{
                      $store.state.login.sLblPassword
                    }}</label>
                  </div>
                  <input
                    class="uk-input"
                    :type="vInputType"
                    v-model="vLoginPw"
                  />
                  <span uk-icon="icon: file-text" @click="doSwitchPassword"
                    >パスワードを表示する</span
                  >
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-secondary uk-width-1-1"
                    type="button"
                    @click="doLogin"
                  >
                    ログイン
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p style="color: red">
          <b>{{ vMessage }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";

export default {
  data() {
    return {
      vComiId: "",
      vLoginId: "",
      vLoginPw: "",
      vUserType: "",
      vMessage: "",
      vInputType: "password",
      tmp: "",
      alias_id: "",
    };
  },
  components: {},
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン情報有無判定
    if (sessionStorage.getItem("storageId")) {
      // ログイン情報を画面にセットする
      this.alias_id = sessionStorage.getItem("storageAliasId");
      this.vLoginId = sessionStorage.getItem("storageId");
      this.vLoginPw = sessionStorage.getItem("storagePw");
    }
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "ログイン",
    });
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ログイン";
  },
  methods: {
    // パスワード表示切替処理
    doSwitchPassword() {
      if (this.vInputType == "password") {
        this.vInputType = "text";
      } else {
        this.vInputType = "password";
      }
    },
    // ログイン処理
    doLogin() {
      if (this.alias_id && this.vLoginId && this.vLoginPw) {
        // ボタン制御
        this.$store.commit("common/doStartServerProcess");
        this.vMessage = "";
        let createHash = require("sha256-uint8array").createHash;
        this.tmp = createHash().update(this.vLoginPw).digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            alias_id: this.alias_id,
            pLoginId: this.vLoginId,
            pLoginPw: this.tmp,
          })
          .then(
            function (response) {
              console.log(response);
              // ログイン処理判定
              if (
                response.data.return_result ==
                constant.cServerReturnResult.success
              ) {
                // ユーザー情報を保持する
                this.$store.commit("common/doLogin", {
                  vAliasId: this.alias_id,
                  vLoginId: this.vLoginId,
                  vLoginPw: this.vLoginPw,
                  // 'vLoginId': response.data.return_data.login_id,
                  // 'vLoginPw': response.data.return_data.com_pw,
                  vComId: response.data.return_data.com_id,
                  vComName: response.data.return_data.com_name,
                  vMailAddress: response.data.return_data.mail_address,
                  vUserType: response.data.return_data.user_type,
                  vUserCode: response.data.return_data.user_code,
                  vUserName: response.data.return_data.user_name,
                  vRegiStatus: response.data.return_data.regi_status,
                  mode: 1,
                });
                // ユーザー種類により遷移画面を分ける
                this.vUserType = this.$store.state.common.sUserType;
                switch (this.vUserType) {
                  // インテリジェント担当者
                  case constant.cUserType.intelligent:
                    console.log("インテリジェント担当者です");
                    this.$router.replace("/intelligent-home");
                    break;
                  // クライアント担当者
                  case constant.cUserType.customer:
                    console.log("クライアント担当者です");
                    this.$router.replace("/customer-home");
                    break;
                  // 回答者
                  case constant.cUserType.respondent:
                    console.log("回答者です");
                    this.$router.replace("/respondent-home");
                    break;
                }
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              } else {
                this.vMessage = response.data.return_message;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit("common/doEndServerProcess");
          });
      }
    },
  },
};
</script>
